import { FORM_ELEMENT, OPERATOR_TYPE } from '@features/filters/types';
import i18n from '@features/i18n';
import { type IFiltersInitialValues } from '@modules/playerOffers/types';

export enum FILTERS_ENTITY {
  ACCEPTED_AT = 'acceptedAt-At',
  ACCEPTED_TO = 'acceptedAt-To',
  BRAND_ID = 'brandId',
  CAMPAIGN_ID = 'campaignId',
  COUNTRY_ID = 'countryId',
  DECLINED_AT = 'declinedAt-At',
  DECLINED_TO = 'declinedAt-To',
  EXPIRES_AT = 'expiresAt-At',
  EXPIRES_TO = 'expiresAt-To',
  FILTER_TYPE = 'filterType',
  GIVEN_AT = 'givenAt-At',
  GIVEN_TO = 'givenAt-To',
  ID = 'id',
  PLAYER_ID = 'playerId',
  STATUS = 'status',
}

export const filterList = [
  {
    value: FILTERS_ENTITY.PLAYER_ID,
    formElement: FORM_ELEMENT.INPUT,
    label: i18n.t('report.offers.grid.filters.control.player.id'),
  },
  {
    value: FILTERS_ENTITY.ID,
    formElement: FORM_ELEMENT.INPUT,
    label: i18n.t('report.offers.grid.filters.control.offer.id'),
  },
  {
    value: FILTERS_ENTITY.BRAND_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
    label: i18n.t('report.offers.grid.filters.control.brand'),
  },
  {
    value: FILTERS_ENTITY.COUNTRY_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
    label: i18n.t('report.offers.grid.filters.control.country'),
  },
  {
    value: FILTERS_ENTITY.STATUS,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
    label: i18n.t('report.offers.grid.filters.control.status'),
  },
  {
    value: FILTERS_ENTITY.GIVEN_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
    label: i18n.t('report.offers.grid.filters.control.given.at.from'),
  },
  {
    value: FILTERS_ENTITY.GIVEN_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
    label: i18n.t('report.offers.grid.filters.control.given.at.to'),
  },
  {
    value: FILTERS_ENTITY.ACCEPTED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
    label: i18n.t('report.offers.grid.filters.control.accepted.at.from'),
  },
  {
    value: FILTERS_ENTITY.ACCEPTED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
    label: i18n.t('report.offers.grid.filters.control.accepted.at.to'),
  },
  {
    value: FILTERS_ENTITY.EXPIRES_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
    label: i18n.t('report.offers.grid.filters.control.expires.at.from'),
  },
  {
    value: FILTERS_ENTITY.EXPIRES_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
    label: i18n.t('report.offers.grid.filters.control.expires.at.to'),
  },
  {
    value: FILTERS_ENTITY.DECLINED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
    label: i18n.t('report.offers.grid.filters.control.declined.at.from'),
  },
  {
    value: FILTERS_ENTITY.DECLINED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
    label: i18n.t('report.offers.grid.filters.control.declined.at.to'),
  },
  {
    value: FILTERS_ENTITY.CAMPAIGN_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
    label: i18n.t('report.offers.grid.filters.control.campaign.name'),
  },
];

export const filtersInitialValues: IFiltersInitialValues = {
  [FILTERS_ENTITY.FILTER_TYPE]: null,
  [FILTERS_ENTITY.ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.STATUS]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.BRAND_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.COUNTRY_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.CAMPAIGN_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.PLAYER_ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.GIVEN_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.EXPIRES_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.GIVEN_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.DECLINED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.ACCEPTED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.EXPIRES_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.DECLINED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.ACCEPTED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
};

export const inputTypeFilters = [FILTERS_ENTITY.PLAYER_ID, FILTERS_ENTITY.ID];

export const startDateTypeFilters = [
  FILTERS_ENTITY.GIVEN_AT,
  FILTERS_ENTITY.EXPIRES_AT,
  FILTERS_ENTITY.DECLINED_AT,
  FILTERS_ENTITY.ACCEPTED_AT,
];

export const endDateTypeFilters = [
  FILTERS_ENTITY.GIVEN_TO,
  FILTERS_ENTITY.EXPIRES_TO,
  FILTERS_ENTITY.DECLINED_TO,
  FILTERS_ENTITY.ACCEPTED_TO,
];

export enum PLAYER_OFFERS_QUERY_PARAM {
  PLAYER_ID = 'player_id',
}
