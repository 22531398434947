import { OPERATOR_TYPE } from '@features/filters/types';

export enum CAMPAIGN_MONITOR_REPORT_FILTERS_NAME {
  BONUS_TEMPLATE_ID = 'bonusTemplateId',
  BRAND_ID = 'brandId',
  CAMPAIGN_ID = 'campaignId',
  CAMPAIGN_TYPE = 'boCampaignType',
  DECISION = 'decision',
  EVENT_ID = 'triggerId',
  FAILED_CHECK = 'failedCheck',
  HIDDEN = 'hidden',
  OFFER_STATUS = 'offerStatus',
  PLAYER_ID = 'playerId',
  SHOW_ADVANCED_FILTERS = 'showAdvancedFilters',
  TRIGGER_DATE_FROM = 'triggerReceivedAt-At',
  TRIGGER_DATE_TO = 'triggerReceivedAt-To',
  TRIGGER_TYPE = 'triggerType',
}

export const campaignMonitorReportFiltersInitialValues = {
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.SHOW_ADVANCED_FILTERS]: false,
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.BRAND_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.DECISION]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.EVENT_ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.LIKE,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.CAMPAIGN_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.TRIGGER_TYPE]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.PLAYER_ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.OFFER_STATUS]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.FAILED_CHECK]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.HIDDEN]: {
    value: false,
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.CAMPAIGN_TYPE]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.BONUS_TEMPLATE_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.TRIGGER_DATE_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.TRIGGER_DATE_FROM]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
};
