import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '@constants';

export const NotFound = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack alignItems="center">
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '6rem',
          marginTop: '5rem',
        }}
        variant="h1"
      >
        404
      </Typography>

      <Typography sx={{ fontWeight: 600, marginTop: '1rem' }}>
        {t('common.404.title')}
      </Typography>

      <Typography color={theme.palette.secondary.main}>
        {t('common.404.subtitle')}
      </Typography>
      <Box marginTop="2rem">
        <Button
          component={NavLink}
          size="large"
          to={ROUTES.BONUS_MANAGEMENT.ROOT}
          variant="contained"
        >
          {t('common.button.go.home')}
        </Button>
      </Box>
    </Stack>
  );
};
