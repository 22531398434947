import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { setupListeners } from '@reduxjs/toolkit/query';
import { router } from '@routes';
import { store } from '@store';

import AuthProvider from '@features/auth/AuthProvider';
import { adapterLocale, localeText } from '@features/i18n';
import I18nProvider from '@features/i18n/I18nextProvider';
import MaintenanceProvider from '@features/maintenance/MaintenanceProvider';
import ThemeCustomization from '@themes/index';

import 'react-toastify/dist/ReactToastify.css';

setupListeners(store.dispatch);

function App() {
  return (
    <ReduxProvider store={store}>
      <ThemeCustomization>
        <MaintenanceProvider>
          <AuthProvider>
            <I18nProvider>
              <LocalizationProvider
                adapterLocale={adapterLocale}
                dateAdapter={AdapterDayjs}
                localeText={localeText}
              >
                <RouterProvider
                  future={{ v7_startTransition: true }}
                  router={router}
                />
                <ToastContainer autoClose={5000} />
              </LocalizationProvider>
            </I18nProvider>
          </AuthProvider>
        </MaintenanceProvider>
      </ThemeCustomization>
    </ReduxProvider>
  );
}

export default App;
