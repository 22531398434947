import { type IQueryPayload } from '@delasport/rule-engine-ui';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type IInitialFilter,
  type ISearchParams,
  OPERATOR_SORT,
  OPERATOR_TYPE,
} from '@features/filters/types';
import { initialFilters } from '@modules/codeCampaigns/submodules/codeCampaignFilters/constants';
import type { Option } from '@shared/form/types';

interface IInitialState {
  searchParams: ISearchParams;
  prevFilters: Record<string, IInitialFilter>;
  dialogState: {
    isConfirmBrandsDialogOpen: boolean;
  };
  activeCodeCampaign: {
    enabled: boolean;
    id: null | string;
    name: null | string;
  };
  codeCampaignConfig: {
    queryError: boolean;
    currentBrands: Option[];
    activationAction: string;
    previousBrands: Option[];
    brandChangeReason: string;
    hasBrandsWithoutAccess: boolean;
    manualCodeQuery?: IQueryPayload;
    depositCodeQuery?: IQueryPayload;
    currentManualCodeQuery?: IQueryPayload;
    currentDepositCodeQuery?: IQueryPayload;
  };
}

export const initialCodeCampaignSearchParams = {
  page: 1,
  size: 10,
  filters: [{ value: true, field: 'enabled', type: OPERATOR_TYPE.EQUAL }],
  sorts: [
    {
      field: 'modified_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: IInitialState = {
  prevFilters: initialFilters,
  searchParams: initialCodeCampaignSearchParams,
  dialogState: {
    isConfirmBrandsDialogOpen: false,
  },
  activeCodeCampaign: {
    id: null,
    name: null,
    enabled: false,
  },
  codeCampaignConfig: {
    queryError: false,
    currentBrands: [],
    previousBrands: [],
    activationAction: '',
    brandChangeReason: '',
    manualCodeQuery: undefined,
    depositCodeQuery: undefined,
    hasBrandsWithoutAccess: false,
    currentManualCodeQuery: undefined,
    currentDepositCodeQuery: undefined,
  },
};

const codeCampaignsSlice = createSlice({
  initialState,
  name: 'codeCampaignsSlice',
  reducers: {
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
    setPrevFilters(
      state: IInitialState,
      action: PayloadAction<Record<string, IInitialFilter>>
    ) {
      state.prevFilters = action.payload;
    },
    setActiveCodeCampaign(
      state: IInitialState,
      action: PayloadAction<IInitialState['activeCodeCampaign']>
    ) {
      state.activeCodeCampaign = action.payload;
    },
    setCampaignDialogState(
      state: IInitialState,
      action: PayloadAction<Partial<IInitialState['dialogState']>>
    ) {
      state.dialogState = { ...state.dialogState, ...action.payload };
    },
    setCodeCampaignConfig(
      state: IInitialState,
      action: PayloadAction<Partial<IInitialState['codeCampaignConfig']>>
    ) {
      state.codeCampaignConfig = {
        ...state.codeCampaignConfig,
        ...action.payload,
      };
    },
  },
});

export default codeCampaignsSlice.reducer;

export const {
  setPrevFilters,
  setSearchParams,
  setCodeCampaignConfig,
  setActiveCodeCampaign,
  setCampaignDialogState,
} = codeCampaignsSlice.actions;
