import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type ISearchParams,
  OPERATOR_SORT,
  OPERATOR_TYPE,
} from '@features/filters/types';
import { CAMPAIGN_MONITOR_REPORT_FILTERS_NAME } from '@modules/campaignMonitorReport/constants';

interface IInitialState {
  searchParams: ISearchParams;
}

export const initialCampaignMonitorSearchParams = {
  page: 1,
  size: 10,
  sorts: [
    {
      order: OPERATOR_SORT.DESC,
      field: 'trigger_received_at',
    },
  ],
  filters: [
    {
      value: false,
      type: OPERATOR_TYPE.EQUAL,
      field: CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.HIDDEN,
    },
  ],
};

const initialState: IInitialState = {
  searchParams: initialCampaignMonitorSearchParams,
};

const campaignMonitorSlice = createSlice({
  initialState,
  name: 'campaignMonitorSlice',
  reducers: {
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
  },
});

export default campaignMonitorSlice.reducer;

export const { setSearchParams } = campaignMonitorSlice.actions;
