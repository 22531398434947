import { OPERATOR_TYPE } from '@features/filters/types';

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  enabled: { value: false, inversion: true, operator: OPERATOR_TYPE.EQUAL },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  primaryCriterion: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  bonuses: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
};

export enum EXECUTION_CONDITIONS_FIELD {
  REPEAT = 'repeatOptions',
  START_DATE = 'startDateTime',
  STOP_REPEAT = 'stopRepeat',
}
