import { type IQueryPayload } from '@delasport/rule-engine-ui';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type IInitialFilter,
  type ISearchParams,
  OPERATOR_SORT,
  OPERATOR_TYPE,
} from '@features/filters/types';
import { initialFilters } from '@modules/scheduledCampaigns/constants';
import { type Option } from '@shared/form/types';

interface IInitialState {
  searchParams: ISearchParams;
  prevFilters: Record<string, IInitialFilter>;
  dialogState: {
    isConfirmBrandsDialogOpen: boolean;
  };
  activeScheduledCampaign: {
    enabled: boolean;
    id: null | string;
    name: null | string;
  };
  scheduledCampaignConfig: {
    activeTab: number;
    criterion: string;
    queryError: boolean;
    isFormErrors: boolean;
    query?: IQueryPayload;
    currentBrands: Option[];
    previousBrands: Option[];
    brandChangeReason: string;
    isBetTypesErrors: boolean;
    isSelectorChanged: boolean;
    hasBrandsWithoutAccess: boolean;
    isDirtyAdditionalConditions: boolean;
    isDisableSportTabDialogOpen: boolean;
    isDisableCasinoTabDialogOpen: boolean;
  };
}

export const initialScheduledCampaignsSearchParams = {
  page: 1,
  size: 10,
  filters: [{ value: true, field: 'enabled', type: OPERATOR_TYPE.EQUAL }],
  sorts: [
    {
      field: 'modified_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: IInitialState = {
  prevFilters: initialFilters,
  searchParams: initialScheduledCampaignsSearchParams,
  dialogState: {
    isConfirmBrandsDialogOpen: false,
  },
  activeScheduledCampaign: {
    id: null,
    name: null,
    enabled: false,
  },
  scheduledCampaignConfig: {
    activeTab: 0,
    criterion: '',
    query: undefined,
    queryError: false,
    currentBrands: [],
    previousBrands: [],
    isFormErrors: false,
    brandChangeReason: '',
    isBetTypesErrors: false,
    isSelectorChanged: false,
    hasBrandsWithoutAccess: false,
    isDirtyAdditionalConditions: false,
    isDisableSportTabDialogOpen: false,
    isDisableCasinoTabDialogOpen: false,
  },
};

const scheduledCampaignsSlice = createSlice({
  initialState,
  name: 'scheduledCampaignsSlice',
  reducers: {
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
    setPrevFilters(
      state: IInitialState,
      action: PayloadAction<Record<string, IInitialFilter>>
    ) {
      state.prevFilters = action.payload;
    },
    setActiveScheduledCampaign(
      state: IInitialState,
      action: PayloadAction<IInitialState['activeScheduledCampaign']>
    ) {
      state.activeScheduledCampaign = action.payload;
    },
    setScheduledCampaignDialogState(
      state: IInitialState,
      action: PayloadAction<Partial<IInitialState['dialogState']>>
    ) {
      state.dialogState = { ...state.dialogState, ...action.payload };
    },
    setScheduledCampaignConfig(
      state: IInitialState,
      action: PayloadAction<Partial<IInitialState['scheduledCampaignConfig']>>
    ) {
      state.scheduledCampaignConfig = {
        ...state.scheduledCampaignConfig,
        ...action.payload,
      };
    },
  },
});

export default scheduledCampaignsSlice.reducer;

export const {
  setPrevFilters,
  setSearchParams,
  setScheduledCampaignConfig,
  setActiveScheduledCampaign,
  setScheduledCampaignDialogState,
} = scheduledCampaignsSlice.actions;
