import { type ICustomField } from '@delasport/rule-engine-ui';
import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import replaceURLParams from '@utils/replaceURLParams';

export const ruleEngineApiSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'ruleEngineApi',
  baseQuery: (...args) => baseQueryCreator(ENV.VITE_BONUS_API_URL)(...args),
});

export const ruleEngineApi = ruleEngineApiSlice
  .enhanceEndpoints({
    addTagTypes: ['rules'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFields: builder.query<ICustomField[], string | undefined>({
        query: (key) => ({
          url: key
            ? replaceURLParams(API.CATALOG.RULE_ENGINE.FIELDS_BY_KEY, { key })
            : API.CATALOG.RULE_ENGINE.FIELDS,
        }),
      }),
    }),
  });

export const { useGetFieldsQuery } = ruleEngineApi;
