import { Fade } from '@mui/material';
import {
  type FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Loader } from '@components';

import { ENV, SYSTEM_ROLES } from '@constants';
import { setAppConfig } from '@features/appConfig/appConfigSlice';
import { type ThemeMode } from '@features/appConfig/types';
import { setCredentials } from '@features/auth/authSlice';
import { useAppDispatch, useTimezone } from '@hooks';
import { POST_MESSAGE_EVENT_TYPE } from '@modules/types';
import { decodeJwt } from '@utils/jwtDecode';
import { parseSearchParams } from '@utils/parseUrlParams';
import { sendPostMessage } from '@utils/sendPostMessage';

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const isIframe = window.self !== window.top;
  const [postMessageToken, setPostMessageToken] = useState('');

  // Description: For debug only. Uncomment this code to use iframe-mode locally #hardcodedJWTtoken
  // const [postMessageToken, setPostMessageToken] = useState(
  //   ENV.VITE_DEFAULT_JWT_TOKEN
  // );

  const isValidJSON = (data: unknown): boolean => {
    if (typeof data !== 'string') {
      return false;
    }
    try {
      JSON.parse(data);
      return true;
    } catch {
      return false;
    }
  };

  const postMessageWorker = useCallback(({ data }: { data: unknown }) => {
    if (typeof data !== 'string' || !isValidJSON(data)) {
      return;
    }

    const { type, token } = JSON.parse(data);

    if (type === 'SET_CREDENTIALS' && token) {
      setPostMessageToken(token);

      sendPostMessage(POST_MESSAGE_EVENT_TYPE.CREDENTIALS_RECEIVED);
    }
  }, []);

  useEffect(() => {
    if (isIframe) {
      window.addEventListener('message', postMessageWorker);
    }

    return () => {
      if (isIframe) {
        window.removeEventListener('message', postMessageWorker);
      }
    };
  }, [postMessageWorker, isIframe]);

  const queryParamToken = parseSearchParams(window.location.search, 'token');

  const theme =
    parseSearchParams(window.location.search, 'themeMode')?.toLowerCase() ??
    'light';

  let superPermissions: SYSTEM_ROLES.PERMISSION[] = [];

  const token = isIframe
    ? (postMessageToken ?? '')
    : (queryParamToken ?? ENV.VITE_DEFAULT_JWT_TOKEN ?? '');

  const {
    userType = '',
    permissions = [],
    userInfo = { username: '' },
  } = decodeJwt(token) ?? {};

  if (userType === SYSTEM_ROLES.ROLE.SUPER_ADMIN) {
    superPermissions = [
      ...Object.entries(SYSTEM_ROLES.PERMISSION).map((p) => p[1]),
    ];
  }

  dispatch(
    setCredentials({
      token,
      userType,
      userInfo,
      permissions: [...superPermissions, ...permissions],
    })
  );

  dispatch(
    setAppConfig({
      mode: theme as ThemeMode,
    })
  );

  useTimezone();

  return isIframe && !token ? (
    <Loader />
  ) : (
    <Fade mountOnEnter unmountOnExit in={true} timeout={2000}>
      <div>{children}</div>
    </Fade>
  );
};

export default AuthProvider;
