import { FC, PropsWithChildren } from 'react';

import { MaintenanceComponent } from '@components/common/Maintenance/MaintenanceComponent';
import { ENV } from '@constants';

const { VITE_MAINTENANCE_MODE_MESSAGE, VITE_MAINTENANCE_MODE_ENABLED } = ENV;

const MaintenanceProvider: FC<PropsWithChildren> = ({ children }) => {
  if (VITE_MAINTENANCE_MODE_ENABLED) {
    return <MaintenanceComponent message={VITE_MAINTENANCE_MODE_MESSAGE} />;
  }

  return children;
};

export default MaintenanceProvider;
